<template>
  <b-modal
    id="tiss-guide-audit-modal"
    hide-header
    hide-footer
    centered
    size="lg"
  >
    <div class="modal-header">
      <div>
        <p class="modal-title">Histórico de ações</p>
      </div>
      <div>
        <v-close
          class="icon"
          @click="$bvModal.hide('tiss-guide-audit-modal')"
        />
      </div>
    </div>
    <div class="modal-body list-area">
      <p class="info-message-box" v-if="tissGuideAudits.length === 0">
        A guia ainda não possui histórico de alterações.
      </p>
      <div class="card" v-else>
        <b-table-simple id="audit-table" striped borderless sticky-header="500px">
          <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">Horário</th>
              <th scope="col">Responsável</th>
              <th scope="col">Descrição</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="audit in tissGuideAudits" :key="audit.id">
              <td class="w-5">
                {{ moment(audit.created_at).format('DD/MM/YYYY') }}
              </td>
              <td class="w-10">
                {{ moment(audit.created_at).format('HH:mm') }}
              </td>
              <td class="w-40">{{ audit.person.name }}</td>
              <td class="w-50">{{ audit.log_message }}</td>
            </tr>
          </tbody>
        </b-table-simple>
      </div>

      <div class="wrapper-button">
        <b-button
          class="wh-button mr-3"
          variant="primary"
          @click="$bvModal.hide('tiss-guide-audit-modal')"
        >
          Concluir
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'

export default {
  name: 'TissGuideAuditModal',
  components: {
    'v-close': Close
  },
  props: {
    tissGuideAudits: {
      type: Array,
      required: true
    }
  },
  methods: {}
}
</script>

<style lang="scss">
#tiss-guide-audit-modal {
  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }

      .list-area {
        .info-message-box {
          background-color: #e6f9ff;
          border-radius: 8px;
          padding: 16px 26px;
          display: flex;
          justify-content: center;
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          align-items: center;
          margin-right: 24px;
          width: 100%;

          .icon {
            width: 24px;
            height: 24px;
            margin-right: 25px;
            stroke: #0088b3;
          }
        }

        .card {
          max-height: 600px;
          border: none;
          #audit-table {
            margin: 0 auto;
            margin-top: 3%;
            margin-bottom: 3%;
            width: 95%;
            border: 1px solid var(--neutral-200);
            border-radius: 8px;

            thead {
              position: sticky;
              top: 0;
              z-index: 3;
              background-color: var(--neutral-100);

              th {
                box-shadow: inset 0 -2px 0 var(--neutral-200);
              }
            }
          }
        }
      }

      .wrapper-button {
        width: 100%;
        display: inline-flex;
        margin: 20px 0 !important;
        justify-content: flex-end;

        button {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: var(--neutral-000);
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: var(--neutral-000);
    border-radius: 0 0 8px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--neutral-300);
    border-radius: 100px;
    border: 3px solid var(--neutral-000);
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: var(--neutral-200);
  }
}
</style>